<template>
  <v-container id="factory-requests" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <v-container class="py-0">
          <form novalidate>
            <v-card class="card-cont">
              <v-row
                class="request-search-bar"
                justify="space-between"
                align="center"
              >
                <v-col cols="4" class="text-field-cont">
                  <v-text-field
                    v-model="search"
                    label="Factory Name"
                    @input="debounceSearch"
                  />
                </v-col>
              </v-row>
            </v-card>
          </form>

          <v-row class="header-container">
            <v-col :cols="requesterColSize" class="text-element-2 text-center"
              >Factory Name</v-col
            >
            <v-col
              v-if="isSuperAdmin"
              cols="2"
              class="text-element-2 text-center"
              >Company</v-col
            >
            <v-col :cols="requesterColSize" class="text-element-2 text-center"
              >City</v-col
            >
            <v-col :cols="requesterColSize" class="text-element-2 text-center"
              >Country</v-col
            >
            <v-col :cols="requesterColSize" class="text-element-2 text-center"
              >Requester</v-col
            >
            <v-col :cols="requesterColSize" class="text-center">Time</v-col>
            <v-col :cols="actionColSize" class="text-center">Actions</v-col>
          </v-row>

          <div v-if="requests.length">
            <v-card
              class="card-cont"
              v-for="request in requests"
              :key="request.id"
            >
              <v-row class="request-container">
                <v-col
                  :cols="requesterColSize"
                  class="text-element text-center"
                >
                  <span>{{ request.name }}</span>
                </v-col>
                <v-col v-if="isSuperAdmin" cols="2" class="text-center">
                  <span>{{ request.organization_name }}</span>
                </v-col>
                <v-col
                  :cols="requesterColSize"
                  class="text-element text-center"
                >
                  <span>{{ request.city }}</span>
                </v-col>
                <v-col
                  :cols="requesterColSize"
                  class="text-element text-center"
                >
                  <span>{{ request.country }}</span>
                </v-col>
                <v-col :cols="requesterColSize" class="text-center">
                  <span>{{ request.requester_org }}</span>
                </v-col>
                <v-col :cols="requesterColSize" class="text-center">
                  <span>{{ new Date(request.time).toLocaleString() }}</span>
                </v-col>
                <v-col :cols="actionColSize" class="text-center">
                  <v-btn
                    @click="openFactoryForm(request)"
                    color="#70D0CE"
                    style="width: 100px"
                    >Add</v-btn
                  >
                  <v-btn
                    @click="deleteFactory(request.id)"
                    color="#EA7A66"
                    style="width: 100px"
                    >Delete</v-btn
                  >
                </v-col>
              </v-row>
            </v-card>
          </div>

          <v-card v-else class="card-cont">
            No factory requests available
          </v-card>

          <v-spacer></v-spacer>
          <Pagination
            style="margin-right: -30px"
            :current-page="currentPage"
            :total-count="totalCount"
            @load-page="loadPage"
            v-if="requests.length"
          />
        </v-container>
      </v-col>
    </v-row>
    <popup-window
      :active="factoryFormPopup.active"
      :hasClose="false"
      noMargin
      @popup-close="factoryFormPopup.active = false"
    >
      <factory-form
        ref="factoryCreationFormBom"
        :loading="factoryFormPopup.creating"
        :factory="factoryFormPopup.requestData"
        @close="closeFactoryForm"
        @create="createFactory"
      />
    </popup-window>
  </v-container>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import Pagination from "../../components/Pagination";
import debounce from "lodash/debounce";
import restAdapter from "../../restAdapter";
import notification from "../../notification";
import auth from "@/auth";
import FactoryForm from "@/components/FactoryForm.vue";

export default {
  components: {
    Pagination,
    FactoryForm,
  },
  data() {
    return {
      currentPage: 1,
      totalCount: 0,
      search: "",
      requests: [],
      loading: false,
      factoryFormPopup: {
        active: false,
        creating: false,
        requestData: null,
      },
    };
  },
  async mounted() {
    await this.fetchFactoryRequests();
  },
  computed: {
    isSuperAdmin() {
      return auth.user?.role?.name === "super_admin";
    },
    requesterColSize() {
      return this.isSuperAdmin ? 1.5 : 1.5;
    },
    actionColSize() {
      return this.isSuperAdmin ? 3 : 3;
    },
  },
  methods: {
    async fetchFactoryRequests() {
      this.loading = true;
      let query = `?page=${this.currentPage}`;

      if (this.search) {
        query += `&query=${this.search}`;
      }

      const endpoint = `/api/factory_requests`;

      try {
        const response = await restAdapter.get(endpoint + query);
        if (response.data) {
          this.requests = response.data.data;
          this.totalCount = response.data.meta.total;
        } else {
          this.requests = [];
        }
      } catch (error) {
        notification.error("Failed to fetch requests");
      } finally {
        this.loading = false;
      }
    },
    debounceSearch: debounce(async function () {
      this.currentPage = 1;
      await this.fetchFactoryRequests();
    }, 200),
    loadPage(page) {
      this.currentPage = page;
      this.fetchFactoryRequests();
    },
    openFactoryForm(request) {
      this.factoryFormPopup.requestData = {
        name: request.name,
        establishedCity: request.city,
        establishedCountry: request.country,
        organization_id: request.organization_id,
      };
      this.factoryFormPopup.selectedRequest = request;
      this.factoryFormPopup.active = true;
    },

    async createFactory(formData) {
      const factoryData = {
        global_organization_id: formData.organization_id,
        name: formData.name,
        established_city: formData.establishedCity,
        established_country: formData.establishedCountry,
      };

      try {
        const response = await restAdapter.post("/api/factories", factoryData);
        if (response.data) {
          notification.success(response.data.message);
          await this.deleteFactoryWithoutConfirmation(
            this.factoryFormPopup.selectedRequest.id
          );
          this.factoryFormPopup.active = false;
          await this.fetchFactoryRequests();
        }
      } catch (error) {
        notification.error(
          error.response?.data?.message || "Failed to add factory"
        );
      }
    },
    async deleteFactory(requestId) {
      const confirmed = confirm(
        "Are you sure you want to delete this factory request?"
      );

      if (!confirmed) {
        return;
      }

      try {
        await restAdapter.delete(`/api/factory_requests/${requestId}`);
        await this.fetchFactoryRequests(); // Refresh requests after deletion
      } catch (error) {
        notification.error("Failed to delete factory request");
      }
    },
    async deleteFactoryWithoutConfirmation(requestId) {
      try {
        await restAdapter.delete(`/api/factory_requests/${requestId}`);
        // You can also refresh the requests here if needed
      } catch (error) {
        notification.error("Failed to delete factory request");
      }
    },
    closeFactoryForm() {
      this.factoryFormPopup.active = false; // Close the popup
    },
  },
};
</script>

<style>
.text-element-5 {
  text-align: right;
  padding-right: 25px;
}
.verification-btn {
  width: 100px;
}
.color_class {
  border: 1px solid #473068;
  color: #442d65 !important;
}
.verification-success {
  background-color: #64d5ca;
}
.verification-error {
  background-color: #f66d9b;
}
.style-paginate {
  border-radius: 3px;
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  margin-right: -20px;
}
.account-align {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #473068;
}
.text-field-cont {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 10px;
}
.title-underline {
  border-bottom: 1px solid #70d0ce;
  width: fit-content;
}
.popup-card {
  padding: 20px;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  text-align: center;
}

.centered-content {
  margin: 0 auto;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
}

.right-top-corner {
  position: absolute;
  top: 5;
  right: 0;
}

.detail-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.label {
  flex: 1;
  font-weight: bold;
  text-align: left;
}

.separator {
  margin: 0 10px;
  text-align: center;
}

.data {
  flex: 2;
  text-align: left;
}

.center-aligned {
  text-align: center;
  margin-top: 20px;
}
.account-search-bar {
  background: #ffffff;
  border-radius: 7px;
}
.btn-cont {
  text-align: right;
}
.card-cont {
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
}
.v-card-actions {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.delete-btn {
  background-color: #e53935;
}

.md-raised {
  margin-top: 3px;
  background-color: #582963;
}
.label,
.separator,
.data {
  flex: 1;
}

.detail-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 10px;
}
.header-container {
  color: #442d65;
  font-weight: 600;
  font-size: 16px;
  margin: -10px 0px -30px 0px;
  text-align: left;
}
.account-container {
  align-items: center;
  color: #442d65;
  font-size: 14px;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
  border-radius: 7px !important;
  background: #ffffff;
  text-align: left;
}
.custom-text-field ::v-deep label {
  color: #442d65;
}
.custom-card-cont {
  margin: 0px -15px;
}
.text-element-btn {
  justify-content: center;
  display: flex;
  align-items: center;
}
.text-element-1 {
  padding-left: 0px;
}
.text-element-2 {
  padding-left: 4px;
}
.theme--light.v-input {
  margin-right: 20px;
}
.theme--light.v-label {
  color: #442d65 !important;
}
.mdi-magnify::before {
  color: #442d65 !important;
}
.mdi-magnify::after {
  color: #442d65 !important;
}
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: #442d65 !important;
}
.v-text-field > .v-input__control > .v-input__slot:after {
  background-color: #442d65;
  border-color: #442d65;
  border-style: solid;
  border-width: thin 0 thin 0;
}
.action {
  width: 10px !important;
  /* border-radius: 40%; */
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
  min-width: 30px;
}
.v-menu__content {
  z-index: 250 !important;
}
</style>
